@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #666e75;
  border-radius: 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="text"] {
  background: transparent;
}
input[type="tel"] {
  outline: none;
  border: none;
}

input[type="number"] {
  background: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.hero-banner-container {
  padding: 40px 40px;
  background-color: #f9f9f9;
  border-radius: 15px;
  position: relative;

  width: 100%;
}

.hero-banner-container h1 {
  font-size: 5em;
  margin-left: -20px;
  text-transform: uppercase;
}
.paginationbtn {
  display: flex;
  width: 330px;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 2rem;
  justify-content: space-evenly;
}
.paginationbtn a {
  padding: 0.5rem;
  margin: 0.5rem;
}

.activePagination a {
  background-color: rgba(249, 211, 66, 0.3);
}

@media screen and (max-width: 768px) {
  .hero-banner-container h1 {
    font-size: 2em;
  }
  .MuiStepper-horizontal {
    display: flex !important;
    flex-direction: column !important;
    gap: 1rem !important;
    align-items: flex-start !important;
    margin-bottom: 2rem;
  }
  .paginationbtn {
    padding: 0.5rem;
    width: 230px;

    font-weight: bold;
    font-size: 1rem;
  }
  .paginationbtn a {
    padding: 0.2rem;
  }
}
.hero-banner-image img {
  width: 300px;
  height: 300px;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.5);
}
